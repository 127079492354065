import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { Layout } from "@layouts/default"
import { Hero } from "@components/hero/hero"
import { ContactPageForm } from "@components/contact-page-form/contact-page-form"
import { SEO } from "@components/seo"

import { formSection } from "@styles/pages/contact/contact.styles"

const ContactPage: React.FC = () => {
  return (
    <Layout>
      <SEO title="Get in touch" description="" />
      <Hero
        title="Get in Touch"
        description={
          <>
            Thank you for your interest in Authsider.
            <br />
            If you&apos;d like to speak with a member of our team, please take a
            moment to fill in the brief form below. An Authsider representative
            will contact you as soon as possible.
          </>
        }
      />
      <section className="contact-form" css={formSection}>
        <Container>
          <Row>
            <Col md={{ span: 6, offset: 3 }}>
              <ContactPageForm />
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  )
}

export default ContactPage
